






























































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import '../scss/AboutProjectHead.scss';
import { IProject } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';
import { AboutProjectMutations } from "@store/modules/about-project/Types";

const NSProject = namespace('storeProject');
const NSUser = namespace('storeUser');
const NSAboutProjects = namespace('storeAboutProject');

@Component({
    name: 'AboutProjectHead',
    components: {
        ButtonFavorite: () => import('@components/buttons/ButtonFavorite.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseSelect: () => import('@components/BaseSelect'),
        PopupFiltersAboutProject: () => import('@components/popups/PopupFiltersAboutProject.vue'),
        FiltersPreview: () => import('@components/FiltersPreview/FiltersPreview.vue'),
    },
})

export default class AboutProjectHead extends Vue {
    $refs!: {
        filterPopup: HTMLFormElement,
        filtersPreview: HTMLFormElement,
    }
    @Prop({}) individualMembersListProp!: any[];
    @Prop({}) juristicMembersListProp!: any[];
    @Prop({}) filteredMembersLength!: number | null;
    @Prop({}) selectedMembers!: any[];
    @Prop({}) changedRow!: any;

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSAboutProjects.Mutation(AboutProjectMutations.M_SET_FILTERS) setFilters!: (payload: any[]) => void;
    @NSAboutProjects.Mutation(AboutProjectMutations.M_SET_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;

    @Prop({ default: [] }) views!: any[];
    @Prop({ default: '' }) activeView;

    resetFiltersState: boolean = false;

    mounted() {
        this.resetFiltersState = true;
        this.setFiltersApply(false);
        this.setFilters([]);
    }

    get individualMembersList():any[] {
        const output:any[] = [];
        this.individualMembersListProp.forEach(item => {
            if (this.changedRow && this.changedRow.id === item.id) {
                output.push(this.changedRow);
            } else {
                output.push(item);
            }
        });
        return output;
    }

    get juristicMembersList():any[] {
        const output:any[] = [];
        this.juristicMembersListProp.forEach(item => {
            if (this.changedRow && this.changedRow.id === item.id) {
                output.push(this.changedRow);
            } else {
                output.push(item);
            }
        });
        return output;
    }

    get actionsForSelect() {
        return [
            {
                action: 'activate',
                label: 'Активировать',
            },{
                action: 'deactivate',
                label: 'Сделать неактививным',
            }
        ];
    }

    openFilters() {
        this.$refs.filterPopup.openPopup();
    }

    cancelFilters() {
        if (this.$refs.filterPopup) {
            this.$refs.filterPopup.cancelFilters();
        }
    }

    onSelectAction(e) {
        switch (e.action) {
            case 'activate':
                this.$emit('activate-selected-items');
                break;
            case 'deactivate':
                this.$emit('deactivate-selected-items');
                break;
            default:
                return 0;
        }
    }

    setFiltersPreview(filtersData) {
        this.$refs.filtersPreview.setFiltersData(filtersData);
    }

    resetFilters(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }
}
